@import '../../utils/mixins';

    .about {

        display: flex;
        flex-direction: column;
        animation: appearOpacity 2s ease forwards;
        
        &_title {
            margin: 85px 54px;
            font-size: 98px;
            font-family: 'SpecialFont';
        }

        &_img { 
            width: 100%; 
            aspect-ratio: 2/1;
            object-fit: cover;
            filter: grayscale(1);
            margin: 0;
            @media only screen and (min-width: $padMinWidth) and (max-width: $padMaxWidth){
                                
            }
            
            @media only screen and (max-width:$mobileMaxWidth){
                aspect-ratio: 1.2/1;
                width: 100%;
            } 
        }

        &_introductionContainer {
            display: flex;
            flex-direction: row;
            justify-content: flex-start;
            
            @media only screen and (min-width: $padMinWidth) and (max-width: $padMaxWidth) {
                @include responsiveMarginLeft;                
            }
            @media only screen and (max-width:$mobileMaxWidth) {
                @include responsiveMarginLeft;
            } 
                  
            p {
                width: 40%;
                text-align: justify;
                font-family: 'MainFont_light';
                font-size: 16px;
                margin-left: 54px;
                padding-top: 98px;
                padding-bottom: 98px;

                @media only screen and (min-width: $padMinWidth) and (max-width: $padMaxWidth) {
                    @include responsiveMarginRight;
                    padding-top: 46px;
                    padding-bottom: 46px;            
                }
                @media only screen and (max-width:$mobileMaxWidth) {
                    @include responsiveMarginRight;
                    font-size: 12px;
                    padding-top: 35px;
                    padding-bottom: 35px;
                }
            }
        }

        &_manifestoContainer {
            padding-left: 54px;
            padding-right: 54px;
            background-color: $colorFirst;
            margin: 0;

            @media only screen and (min-width: $padMinWidth) and (max-width: $padMaxWidth){
                padding-left: 50px;
                padding-right: 15px;                
            }
            @media only screen and (max-width:$mobileMaxWidth){
                padding-left: 50px;
                padding-right: 15px;
            } 

            &_title {
                display: flex;
                justify-content: center;
                width: 100%;
                font-family: 'SpecialFont';
                font-size: 54px;
                padding-top: 98px;
                margin: auto;
                @media only screen and (min-width: $padMinWidth) and (max-width: $padMaxWidth){
                    font-size: 54px;
                    padding-top: 54px;            
                }
                
                @media only screen and (max-width:$mobileMaxWidth){
                    font-size: 22px;
                    padding-top: 35px;
                } 
            }
            &_secondTitle {
                display: flex;
                justify-content: flex-start;
                width: 50%;
                font-family: 'SpecialFont';
                font-size: 35px;
                padding-top: 98px;

                @media only screen and (min-width: $padMinWidth) and (max-width: $padMaxWidth){
                    width: 100%;
                    padding-top: 54px;           
                }
                @media only screen and (max-width:$mobileMaxWidth){
                    font-size: 16px;
                    width: 100%;  
                    padding-top: 22px;
                } 
            }

            &_description {
                display: flex;
                text-align: justify;
                width: 50%;
                font-family: 'MainFont_light';
                font-size: 16px;
                @media only screen and (min-width: $padMinWidth) and (max-width: $padMaxWidth){
                    margin: 0;
                    width: auto;
                }
                
                @media only screen and (max-width:$mobileMaxWidth){
                    font-size: 12px;
                    margin: 0;
                    width: auto;
                } 
            }

            &_list {
                margin: 0;
                padding: 98px 0;
                display: flex;
                flex-direction: column;
                justify-content: space-between;
                row-gap: 35px;
                list-style-type: none;
                width: 95%;
                @media only screen and (min-width: $padMinWidth) and (max-width: $padMaxWidth){  
                    padding: 54px 0;      
                }
                
                @media only screen and (max-width:$mobileMaxWidth){
                    padding: 35px 0;      
                    
                } 
    
                &_item {
                    
                    p {
                        font-family: 'SpecialFont';
                        font-size: 25px;
                        margin: auto;
                        text-align: left;
                        
                        @media only screen and (min-width: $padMinWidth) and (max-width: $padMaxWidth){        
                            font-size: 16px;
                            text-align: left;
                        }
                        
                        @media only screen and (max-width:$mobileMaxWidth){
                            font-size: 14px;
                            text-align: center;
                            
                        } 
                    }
                }
            }
        }

        &_mentionsText {

            color: $colorLightGrey;
            display: flex;
            flex-direction: column;
            text-align: center;
            width: 50%;
            margin: 0;
            font-family: 'MainFont_light';
            margin: auto;
            font-size: 16px;
            padding-top: 35px;
            padding-right: 0; 
            
            @media only screen and (min-width: $padMinWidth) and (max-width: $padMaxWidth){        
                font-size: 16px;
                padding-top: 54px;
                width: 70%;
            }
            
            @media only screen and (max-width:$mobileMaxWidth){
                font-size: 12px;
                margin: 0 auto 0 20%;
                padding-top: 35px;
                width: 70%;
            }  

            a {
                color: $colorLightGrey;
            }
             
        }
        
        &_collabsGrid {
            padding-top: 54px;
            padding-bottom: 54px;
            padding-right: 0; 
            
            width: 600px;
            margin: auto;
            display: grid;
            grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
            row-gap: 35px;
            column-gap: 35px;
            list-style-type: none;
            @media only screen and (min-width: $padMinWidth) and (max-width: $padMaxWidth){        
                row-gap: 22px;
                width: 500px;
                padding-top: 54px
            }
            
            @media only screen and (max-width:$mobileMaxWidth){
                grid-template-columns: 1fr 1fr 1fr;
                margin: 0;
                width: 85%;
                padding-top: 35px;
                row-gap: 22px;
                padding-left: 48px;
                
            }  

            &_item {

                width: 100px;
                @media only screen and (min-width: $padMinWidth) and (max-width: $padMaxWidth){        
                    margin: auto;
                }
                
                @media only screen and (max-width:$mobileMaxWidth){
                    margin: auto;
                    width: 60px;
                }  

                img {
                    width: 100px;
                    @media only screen and (min-width: $padMinWidth) and (max-width: $padMaxWidth){        
                        width: 80px;
                        margin: auto;
                    }
                    
                    @media only screen and (max-width:$mobileMaxWidth){
                        width: 60px;
                        margin: auto;
                        align-items: center;
                    }  
                }
            }
        }

    }