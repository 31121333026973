@import '../../utils/mixins';

.projectForm {
    display: flex;
    flex-direction: column;
    width: 80vw;
    height: 100%;
    margin: 5vh auto;
    background: $colorLightGrey;
    padding: 16px 46px;
    box-sizing: border-box;
    overflow: scroll;
    font-family: 'MainFont_bold';
    position: relative;
    animation: appearOpacity 2s ease forwards;

    &_stickyCancelButton {
        width: auto;
        display: flex;
        color: $colorDarkGrey;
        background: none;
        border: none;
        position: sticky;
        top: 20px;
        
        &_icon {
            height: 2em;
            
            &:hover {
                cursor: pointer;
            }
        }
    }

    &_welcomeContainer {
        width: 70%;
        margin: 54px auto;
        background-color: $colorSecond;
        
        
        &_mainWelcome {
            
            font-family: 'SpecialFont';
            font-size: 35px;
            padding: 10px;
            margin: 62px auto 35px auto;
            text-align: center;
            color: $colorLightGrey;
            background-color: $colorDarkGrey;
            width: 40%;
            rotate: -5deg;
            filter: drop-shadow(10px 10px 0px $colorFirst);
        }

        &_imageWelcome {
            display: flex;
            width: 200px;
            margin: 0 auto 54px auto;
        }

        &_secondWelcome {
            font-family: 'MainFont_light';
            text-align: center;
            margin-bottom: 54px;
            color: $colorLightGrey;
        }
    }

    
        
    &_mainInfosContainer {
        width: 85%;
        margin: auto;
        flex-direction: column;
        justify-content: space-between;
        column-gap: 54px;
        display: flex;
        &_textField, &_arrayField {
            width: 100%;
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            margin: 10px 0;
            box-sizing: border-box;
            // border-bottom: solid 1px $colorDarkGrey;

            label {
                width: 50%;
            }

            input, #inputProjectType {
                border: none;
                background-color: $colorFirst;
                font-family: 'MainFont_light';
                font-size: 16px;
                width: 70%;
            }
        }
    }

    &_imagesFieldsContainer {
        width: 85%;
        margin: auto;
        

        &_projectImagesContainer, &_makingOfImagesContainer {
            display: flex;
            flex-direction: column;
            width: 100%;
            height: auto;
            margin: 30px auto 0 auto;
            box-sizing: border-box;

            &_imageField {
                display: flex;
                flex-direction: column;
                justify-content: center;
                height: auto;
                

                label {
                    
                    margin: auto;
                    @include Button;
                    font-family: 'MainFont_light';
                    font-size: 16px;
                    
                }
                
                input {
                    border: none;
                    background-color: $colorFirst;
                    font-family: 'MainFont_light';
                    font-size: 16px;
                    margin-right: 12px;
                    width: auto;
                    
                    
                }
                
                &_sampleContainer {
                    margin: auto;

                    img {
                        width: 300px;
                        margin: 30px auto;
                    }

                    &_buttonsSystem { 
                        
                        &--displayOn {
                            display: flex;
                            flex-direction: row;
                            justify-content: center;
                            column-gap: 25px;
                            margin-bottom: 25px;
                            button {
                                @include Button;
                                font-size: 16px; 
                                font-family: 'MainFont_light';      
                            }
                        }
                        &--displayOff {
                            display: none; 
                        }
                    }
                }
            }

            p {
                font-family:'MainFont_bold';
                margin: 16px auto;
            } 
        }

        &_makingOfImagesContainer {
            background-color: $colorFirst;
            padding-bottom: 54px;
            p {
                padding: 30px;
                margin: auto;
                box-sizing: border-box;
            }

            &_imageField {
                margin: 54px auto 0 auto;

                label {
                    background-color: $colorDarkGrey;
                }
            }
        }

        &_projectImagesContainer {
            
            &_imageField {
                margin: 54px auto;

            }
        }
    }

    &_descriptionsContainer {

        width: 85%;
        margin: 30px auto 0 auto;
        display: flex;
        flex-direction: column;
        row-gap: 35px;

        &_textField {
    
            margin: 10px 0;
            box-sizing: border-box;
            width: 100%;
    
            &_textarea {
                border: none;
                background-color: $colorFirst;
                font-family: 'MainFont_light';
                font-size: 16px;
                margin-right: 12px;
                padding: 16px;
                box-sizing: border-box;
                width: auto;
                max-width: 100%;
                min-width: 100%;
                height: 200px;
                resize: vertical;
                line-height: 100%;
            }
        }
    }
    
    &_arraysContainer {
        width: 85%;
        margin: auto;
        display: flex;
        flex-direction: column;

        &_arrayField {

            p {
                margin-bottom: 25px;
            }

            margin: 30px auto 0 auto;
            box-sizing: border-box;
            width: 100%;

            &_fields {
                display: flex;
                flex-direction: row;
                justify-content: space-between;
                column-gap: 5px;
                width: 100%;
                margin-top: 8px;
                // border-bottom: solid 1px $colorDarkGrey;

                &_line {
                    display: flex;
                    flex-direction: column;
                    justify-content: flex-start;
                    margin: 0;
                    column-gap: 5px;
                    width: 100%;
                    
                    label {
                        font-size: 16px;
                        line-height: 200%;
                        font-family:'MainFont_bold';
                        width: auto;
                    }
                    input {
                        width: 100%;
                        box-sizing: border-box;
                    }
                }
            }

            input, &_textarea, select {
                border: none;
                background-color: $colorFirst;
                font-family: 'MainFont_light';
                font-size: 16px;
                margin-right: 6px;
            }

            input, &_textarea {
                width: 95%;
            }

            select {
                width: 95%;
            }

            button {
                
                @include AddFieldButton;
                font-family: 'MainFont_light';
                font-size: 16px;
                line-height: 50%;
                height: auto;
                margin-top: 16px;
                margin-bottom: 0;
            }

            &_textarea {
                // max-width: 100%;
                // min-width: 100%;
                height: auto;
                line-height: 100%;
                resize: vertical;
            }
        }
    }

    &_buttons {
        display: flex;
        flex-direction: row;
        column-gap: 25px;
        margin: 32px auto 98px auto;
    
        button {
            @include Button;
        }
    }

    &_loaderContainer {
        &--displayOn {
            display: block;
        }
        &--displayOff {
            display: none;
        }
    }
}