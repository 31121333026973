@import '../../utils/mixins';

.error404 {
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: auto;
    width: 100%;
    margin: 10% auto 0 auto;
    @media only screen and (min-width: $padMinWidth) and (max-width: $padMaxWidth){
        margin: 10% auto 0 auto;
    }
    
    @media only screen and (max-width:$mobileMaxWidth){
        margin: 20% auto 0 auto;
    }      
    &_container {
       
        margin: auto;
        &_404 {
            display: flex;
            margin: auto;
            font-family: 'SpecialFont';
            font-style: 'bold';
            font-size: 300px;
            @media only screen and (min-width: $padMinWidth) and (max-width: $padMaxWidth){
                font-size: 200px;
            }
            
            @media only screen and (max-width:$mobileMaxWidth){
                font-size: 100px;
                margin: 0 0 0 50px;
            }      
        };

        &_text {
            display: flex;
            margin: auto;
            justify-content: center;
            font-family: 'MainFont_lightItalic';
            font-size: 22px;
            @media only screen and (min-width: $padMinWidth) and (max-width: $padMaxWidth){
                font-size: 22px;
            }
            
            @media only screen and (max-width:$mobileMaxWidth){
                font-size: 16px;
                margin: 0 0 0 50px;
            }  
        };
    }

}