@import '../../utils/mixins';

.footer {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    column-gap: 22px;
    height: auto;
    padding: 0 54px 0 54px;
    box-sizing: border-box;
    background-color: $colorFirst;
    // border-top: 1px solid $colorDarkGrey;
    margin-top: 98px;
    @media only screen and (min-width: $padMinWidth) and (max-width: $padMaxWidth){
        display: none;
    }
    
    @media only screen and (max-width:$mobileMaxWidth){
        display: none;
    } 

    &--displayOff {
        display: none;
    } 

    &_leftButtonContainer, &_rightButtonContainer {
        
        display: flex;
        flex-direction: column;
        justify-content: center;
        margin: auto;
        
        &_button {

            width: 22px;
            
            &_icon {
                
                font-size: 19px; 

                &--displayOff {
                    display: none;
                }
                &--displayOn {
                    animation: appearOpacity 1s ease forwards;
                    display: block;
                    &:hover {
                        cursor: pointer;
                    }
                }
            }
        }
    }

    &_list {
        display: flex;
        flex-direction: row;
        height: 100%;
        column-gap: 25px;
        padding: 0;
        margin: 0;
        overflow: auto;

       
        &_item {
            padding: 21px 0 12px 0;
            margin: auto 0;
            list-style-type: none;
            height: 100%;


            &--selected {
                background-color: $colorLightGrey;
            }

            &:hover {
                background-color: $colorLightGrey;
                cursor: pointer;
            }
            a {
                display: flex;
                flex-direction: column;
                justify-content: center;
                row-gap: 3px;
                text-decoration: none;
                height: 100%;
                
                

                img {
                    
                    width: 100%;
                    aspect-ratio: 2/1;
                    object-fit: cover;
                    
                 }
     
                p {
                    font-family: 'SpecialFont';
                    color: $colorDarkGrey;
                    font-size: 16px;
                    margin: 9px 3px 0px 3px;
                    width: 132px;
                    height: 16px;
                    overflow: hidden;
                    
                }
            }   
        }
    }
}