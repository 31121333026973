@import '../../utils/mixins';

.homePage {
        width: 100vw;
        height: 100vh;
        display: flex;
        position: fixed;
        top: 0;
        left: 0;

    &_loader {

        &--displayOn {
            display: flex;
            z-index: 2;
        }
        &--displayOff {
            display: none;
        }
    }
    
    &_image {

        width: 100%;
        height: 100%;
        object-fit: cover;
        animation: darkAppear 2s ease forwards;

        &--blackBg {
            background-color: $colorDarkGrey;
        }
    }

    &_button {
        display: flex;
        flex-direction: column;
        position: fixed;
        bottom: 98px;
        left: 10%;

        a {
            display: flex;
            flex-direction: column;
            justify-content: center;
            padding: 0 30px;
            box-sizing: border-box;
            width: 400px;
            aspect-ratio: 4;
            // height: 114px;
            background-color: $colorFirst;
            border: none;
            font-family: 'SpecialFont';
            text-decoration: none;
            color: $colorDarkGrey;
            margin: auto;
            @media only screen and (min-width: $padMinWidth) and (max-width: $padMaxWidth){

            }
            
            @media only screen and (max-width:$mobileMaxWidth){
                width: 250px;
                aspect-ratio: 4;
            }  
            
            h1 {
                font-size: 35px;
                @media only screen and (min-width: $padMinWidth) and (max-width: $padMaxWidth){

                }
                
                @media only screen and (max-width:$mobileMaxWidth){
                    font-size: 25px;
                }  
            }

        &:hover {
            cursor: pointer;
            color: $colorLightGrey;
            background-color: $colorDarkGrey;
        }
    }
    }
}