@import '../../utils/mixins';

.errorText {

    @include AlertTextStyle;
    margin: 0 auto;

    &_true {
        @include AlertTextStyle;
        color: red;
        animation: appearOpacity 0.5s ease forwards;
        margin: 10px auto;
        width: 100%;
        display: flex;
        flex-direction: row;
        justify-content: center;
    }
    &_false {
        display: none;
    }
}