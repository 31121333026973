@import '../../utils/mixins';

.header {

   
    margin: 54px 0 25px 0;
    background-color: $colorFirst;
    position: -webkit-sticky; /* Pour la prise en charge de WebKit (Safari, Chrome) */
    position: sticky;
    top: 0px;
    z-index: 3;
    @media only screen and (min-width: $padMinWidth) and (max-width: $padMaxWidth){
        position: fixed;
        height: 100vh;
    }
    
    @media only screen and (max-width:$mobileMaxWidth){
        position: fixed;
        height: 100vh;
    } 
    
    &--displayOff {
        display: none;
    }

    &--open {
        animation: openHeader 0.5s ease forwards;
        margin:0;
        @media only screen and (min-width: $padMinWidth) and (max-width: $padMaxWidth){
            animation: openVerticalHeader 0.5s ease forwards;
        }
        
        @media only screen and (max-width:$mobileMaxWidth){
            animation: openVerticalHeader 0.5s ease forwards;
        } 
    }

    &--close {
        animation: closeHeader 0.5s ease forwards;
        margin:0;
        @media only screen and (min-width: $padMinWidth) and (max-width: $padMaxWidth){
            animation: closeVerticalHeader 0.5s ease forwards;
        }
        
        @media only screen and (max-width:$mobileMaxWidth){
            animation: closeVerticalHeader 0.5s ease forwards;
        } 
    }

    &_hamburger {
        height: 100%;
        border: none;
        background: none;
        margin: auto;

        &_desktop {
            display: flex;
            @media only screen and (min-width: $padMinWidth) and (max-width: $padMaxWidth){
                display: flex;
            }
            
            @media only screen and (max-width:$mobileMaxWidth){
                display: flex; 
            } 
        }

        &_responsive {
            display: none;
            @media only screen and (min-width: $padMinWidth) and (max-width: $padMaxWidth){
                display: flex;
            }
            
            @media only screen and (max-width:$mobileMaxWidth){
                display: flex; 
            } 
        }
        
        &_icon {
            height: 16px;
            margin: auto;
            color: $colorDarkGrey;
            
        }

        &--displayOff {
            animation: hideHamburger 0.5s ease forwards;
            display: none;
        }

        &--displayOn {
            animation: showHamburger 0.5s ease forwards;
        }
    }

    &_nav {
        &--displayOff {
            animation: navOpenInvert 0.5s ease forwards;
            display: none;
        }
        &--displayOn {
            animation: navOpen 0.5s ease forwards;
            display: flex;
            @media only screen and (min-width: $padMinWidth) and (max-width: $padMaxWidth){
                animation: navOpen 2s ease forwards;
            };
            @media only screen and (max-width:$mobileMaxWidth){
                animation: navOpen 2s ease forwards;
            } 
        }

        height: 100%;
        flex-direction: row;
        justify-content: space-between;

        @media only screen and (min-width: $padMinWidth) and (max-width: $padMaxWidth){
            flex-direction: column;
            justify-content: center;
            padding-top: 20%;
            padding-bottom: 20%;
            box-sizing: border-box;
            
        };
        @media only screen and (max-width:$mobileMaxWidth){
            flex-direction: column;
            justify-content: center;
            height: 100%;
            padding-top: 20%;
            padding-bottom: 20%;
            box-sizing: border-box;
        }  

        &_menu {
            display: flex;
            flex-direction: row;
            width: 90%;
            column-gap: 1vw;
            margin-left: 54px;
            
            @media only screen and (min-width: $padMinWidth) and (max-width: $padMaxWidth){
                flex-direction: column;
                row-gap: 16px;
                margin: auto;
                justify-content: center;
                width: 100%;
            };
            @media only screen and (max-width:$mobileMaxWidth){
                flex-direction: column;
                margin: auto;
                row-gap: 16px;
                justify-content: center;
                width: 100%;
            }  

            &_item {
                display: flex;
                background: none;
                border: none;
                color: $colorDarkGrey;
                font-family: 'SpecialFont';
                text-decoration: none;
                padding: 0;
                margin: auto 0;
                height: 100%;

                &--displayOn {
                    display: flex;
                }
                &--displayOff {
                    display: none;
                }
                
                &--selected {
                    text-decoration: underline;
                }

                @media only screen and (min-width: $padMinWidth) and (max-width: $padMaxWidth){
                    margin: auto;
                };
                @media only screen and (max-width:$mobileMaxWidth){
                    margin: auto;
                }  

                p:hover {
                    cursor: pointer;
                    text-decoration: underline;
                }

                h2, p {
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    color: $colorDarkGrey;
                    font-family: 'SpecialFont';
                    font-size: 33px;
                    padding: 0 5px;
                    margin: 0;
                    text-decoration: none;
                    height: 100%;
                    @media only screen and (min-width: $padMinWidth) and (max-width: $padMaxWidth){
                        font-size: 35px;
                    };
                    @media only screen and (max-width:$mobileMaxWidth){
                        font-size: 1.5rem;
                    }
                    
                    &:hover {
                        cursor: pointer;
                    }
                }

                &_dashboardNav {
                    opacity: 0.5;
                }
            }
        }

        &_socials {
            display: flex;
            flex-direction: row;
            column-gap: 1.8vw;
            margin: auto 54px auto 0px;
            box-sizing: border-box;
            @media only screen and (min-width: $padMinWidth) and (max-width: $padMaxWidth){
                flex-direction: column;
                margin: auto;
                justify-content: center;
            };
            @media only screen and (max-width:$mobileMaxWidth){
                flex-direction: column;
                margin: auto;
                justify-content: center;
            }  
            a svg {
                color: $colorDarkGrey;
                height: 28px;
                width: 28px;
                margin: auto;
                padding: 4px;
                box-sizing: border-box;
                border-radius: 28px;

                &:hover {
                    color: $colorFirst;
                    background-color: $colorDarkGrey;
                    
                    
                    
                }
            }
        }
    }
    &_contactModal {

        &--open {
            z-index: 1;
            position: fixed;
            align-items: center;
            justify-content: center;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background:rgba(0, 0, 0, 0.8);
            display: flex;
        }
        &--close {
            display: none;
        }
    }
}