@import '../../utils/mixins';

.dndItem {
    position: relative;
    
    &_15 {
        &_item_0 {
            width: 100%;
            @include mainGalleryRatio1;
            grid-column-start: 1;
            grid-column-end: 7;
        }
        &_item_1 {
            height: 100%;
            aspect-ratio: 1;
            grid-row-start: 2;
            grid-row-end: 3;
            grid-column-start: 1;
            grid-column-end: 4;
        }
        &_item_2 {
            height: 100%;
            aspect-ratio: 1;
            grid-row-start: 3;
            grid-row-end: 4;
            grid-column-start: 1;
            grid-column-end: 4;
        }
        &_item_3 {
            height: 100%;
            width: 100%;
            grid-column-start: 4;
            grid-column-end: 7;
            grid-row-start: 2;
            grid-row-end: 4;
        }
        &_item_4 {
            aspect-ratio: 2/1;
            width: 100%;
            grid-column-start: 1;
            grid-column-end: 7;
            grid-row-start: 4;
            grid-row-end:5 ;
        }

        &_item_5 {
            aspect-ratio: 1.5/1;
            width: 100%;
            grid-column-start: 1;
            grid-column-end: 5;
            grid-row-start: 5;
            grid-row-end: 6;
        }

        &_item_6 {
            height: 100%;
            width: 100%;
            grid-column-start: 5;
            grid-column-end: 7;
            grid-row-start: 5;
            grid-row-end: 6;
        }
        &_item_7 {
            aspect-ratio: 1/1;
            width: 100%;
            grid-column-start: 1;
            grid-column-end: 3;
            grid-row-start: 6;
            grid-row-end: 7;
        }
        &_item_8 {
            aspect-ratio: 1/1;
            width: 100%;
            grid-column-start: 3;
            grid-column-end: 5;
            grid-row-start: 6;
            grid-row-end: 7;
        }
        &_item_9 {
            aspect-ratio: 1/1;
            width: 100%;
            grid-column-start: 5;
            grid-column-end: 7;
            grid-row-start: 6;
            grid-row-end: 7;
        }
        &_item_10 {
            height: 100%;
            width: 100%;
            grid-column-start: 1;
            grid-column-end: 3;
            grid-row-start: 7;
            grid-row-end: 8;
        }
        &_item_11 {
            width: 100%;
            aspect-ratio: 1.5/1;
            grid-column-start: 3;
            grid-column-end: 7;
            grid-row-start: 7;
            grid-row-end: 8;
        }
        &_item_12 {
            aspect-ratio: 1/1;
            width: 100%;
            grid-column-start: 1;
            grid-column-end: 3;
            grid-row-start: 8;
            grid-row-end: 9;
        }
        &_item_13 {
            aspect-ratio: 1/1;
            width: 100%;
            grid-column-start: 3;
            grid-column-end: 5;
            grid-row-start: 8;
            grid-row-end: 9;
        }
        &_item_14 {
            aspect-ratio: 1/1;
            width: 100%;
            grid-column-start: 5;
            grid-column-end: 7;
            grid-row-start: 8;
            grid-row-end: 9;
        }
    }
    
    &_14 {
        &_item_0 {
            width: 100%;
            @include mainGalleryRatio1;
            grid-column-start: 1;
            grid-column-end: 7;
        }
        &_item_1 {
            height: 100%;
            aspect-ratio: 1;
            grid-row-start: 2;
            grid-row-end: 3;
            grid-column-start: 1;
            grid-column-end: 4;
        }
        &_item_2 {
            height: 100%;
            aspect-ratio: 1;
            grid-row-start: 3;
            grid-row-end: 4;
            grid-column-start: 1;
            grid-column-end: 4;
        }
        &_item_3 {
            height: 100%;
            width: 100%;
            grid-column-start: 4;
            grid-column-end: 7;
            grid-row-start: 2;
            grid-row-end: 4;
        }
        &_item_4 {
            aspect-ratio: 2/1;
            width: 100%;
            grid-column-start: 1;
            grid-column-end: 7;
            grid-row-start: 4;
            grid-row-end:5 ;
        }

        &_item_5 {
            aspect-ratio: 1.5/1;
            width: 100%;
            grid-column-start: 1;
            grid-column-end: 5;
            grid-row-start: 5;
            grid-row-end: 6;
        }

        &_item_6 {
            height: 100%;
            width: 100%;
            grid-column-start: 5;
            grid-column-end: 7;
            grid-row-start: 5;
            grid-row-end: 6;
        }
        &_item_7 {
            aspect-ratio: 1/1;
            width: 100%;
            grid-column-start: 1;
            grid-column-end: 3;
            grid-row-start: 6;
            grid-row-end: 7;
        }
        &_item_8 {
            aspect-ratio: 1/1;
            width: 100%;
            grid-column-start: 3;
            grid-column-end: 5;
            grid-row-start: 6;
            grid-row-end: 7;
        }
        &_item_9 {
            aspect-ratio: 1/1;
            width: 100%;
            grid-column-start: 5;
            grid-column-end: 7;
            grid-row-start: 6;
            grid-row-end: 7;
        }
        &_item_10 {
            height: 100%;
            width: 100%;
            grid-column-start: 1;
            grid-column-end: 3;
            grid-row-start: 7;
            grid-row-end: 8;
        }
        &_item_11 {
            width: 100%;
            aspect-ratio: 1.5/1;
            grid-column-start: 3;
            grid-column-end: 7;
            grid-row-start: 7;
            grid-row-end: 8;
        }
        &_item_12 {
            width: 100%;
            aspect-ratio: 1.5/1;
            grid-column-start: 1;
            grid-column-end: 5;
            grid-row-start: 8;
            grid-row-end: 9;
        }
        &_item_13 {
            height: 100%;
            width: 100%;
            grid-column-start: 5;
            grid-column-end: 7;
            grid-row-start: 8;
            grid-row-end: 9;
        }
    }
    &_13 {
        &_item_0 {
            width: 100%;
            @include mainGalleryRatio1;
            grid-column-start: 1;
            grid-column-end: 7;
        }
        &_item_1 {
            height: 100%;
            aspect-ratio: 1;
            grid-row-start: 2;
            grid-row-end: 3;
            grid-column-start: 1;
            grid-column-end: 4;
        }
        &_item_2 {
            height: 100%;
            aspect-ratio: 1;
            grid-row-start: 3;
            grid-row-end: 4;
            grid-column-start: 1;
            grid-column-end: 4;
        }
        &_item_3 {
            height: 100%;
            width: 100%;
            grid-column-start: 4;
            grid-column-end: 7;
            grid-row-start: 2;
            grid-row-end: 4;
        }
        &_item_4 {
            aspect-ratio: 2/1;
            width: 100%;
            grid-column-start: 1;
            grid-column-end: 7;
            grid-row-start: 4;
            grid-row-end:5 ;
        }

        &_item_5 {
            aspect-ratio: 1.5/1;
            width: 100%;
            grid-column-start: 1;
            grid-column-end: 5;
            grid-row-start: 5;
            grid-row-end: 6;
        }

        &_item_6 {
            height: 100%;
            width: 100%;
            grid-column-start: 5;
            grid-column-end: 7;
            grid-row-start: 5;
            grid-row-end: 6;
        }
        &_item_7 {
            aspect-ratio: 1/1;
            width: 100%;
            grid-column-start: 1;
            grid-column-end: 3;
            grid-row-start: 6;
            grid-row-end: 7;
        }
        &_item_8 {
            aspect-ratio: 1/1;
            width: 100%;
            grid-column-start: 3;
            grid-column-end: 5;
            grid-row-start: 6;
            grid-row-end: 7;
        }
        &_item_9 {
            aspect-ratio: 1/1;
            width: 100%;
            grid-column-start: 5;
            grid-column-end: 7;
            grid-row-start: 6;
            grid-row-end: 7;
        }
        &_item_10 {
            height: 100%;
            width: 100%;
            grid-column-start: 1;
            grid-column-end: 3;
            grid-row-start: 7;
            grid-row-end: 8;
        }
        &_item_11 {
            width: 100%;
            aspect-ratio: 1.5/1;
            grid-column-start: 3;
            grid-column-end: 7;
            grid-row-start: 7;
            grid-row-end: 8;
        }
        &_item_12 {
            width: 100%;
            aspect-ratio: 2/1;
            grid-column-start: 1;
            grid-column-end: 7;
            grid-row-start: 8;
            grid-row-end: 9;
        }
    }
    &_12 {
        &_item_0 {
            width: 100%;
            @include mainGalleryRatio1;
            grid-column-start: 1;
            grid-column-end: 7;
        }
        &_item_1 {
            height: 100%;
            aspect-ratio: 1;
            grid-row-start: 2;
            grid-row-end: 3;
            grid-column-start: 1;
            grid-column-end: 4;
        }
        &_item_2 {
            height: 100%;
            aspect-ratio: 1;
            grid-row-start: 3;
            grid-row-end: 4;
            grid-column-start: 1;
            grid-column-end: 4;
        }
        &_item_3 {
            height: 100%;
            width: 100%;
            grid-column-start: 4;
            grid-column-end: 7;
            grid-row-start: 2;
            grid-row-end: 4;
        }
        &_item_4 {
            aspect-ratio: 2/1;
            width: 100%;
            grid-column-start: 1;
            grid-column-end: 7;
            grid-row-start: 4;
            grid-row-end:5 ;
        }

        &_item_5 {
            aspect-ratio: 1.5/1;
            width: 100%;
            grid-column-start: 1;
            grid-column-end: 5;
            grid-row-start: 5;
            grid-row-end: 6;
        }

        &_item_6 {
            height: 100%;
            width: 100%;
            grid-column-start: 5;
            grid-column-end: 7;
            grid-row-start: 5;
            grid-row-end: 6;
        }
        &_item_7 {
            aspect-ratio: 1/1;
            width: 100%;
            grid-column-start: 1;
            grid-column-end: 3;
            grid-row-start: 6;
            grid-row-end: 7;
        }
        &_item_8 {
            aspect-ratio: 1/1;
            width: 100%;
            grid-column-start: 3;
            grid-column-end: 5;
            grid-row-start: 6;
            grid-row-end: 7;
        }
        &_item_9 {
            aspect-ratio: 1/1;
            width: 100%;
            grid-column-start: 5;
            grid-column-end: 7;
            grid-row-start: 6;
            grid-row-end: 7;
        }
        &_item_10 {
            height: 100%;
            width: 100%;
            grid-column-start: 1;
            grid-column-end: 3;
            grid-row-start: 7;
            grid-row-end: 8;
        }
        &_item_11 {
            width: 100%;
            aspect-ratio: 1.5/1;
            grid-column-start: 3;
            grid-column-end: 7;
            grid-row-start: 7;
            grid-row-end: 8;
        }
    }
    &_11 {
        &_item_0 {
            width: 100%;
            @include mainGalleryRatio1;
            grid-column-start: 1;
            grid-column-end: 7;
        }
        &_item_1 {
            height: 100%;
            aspect-ratio: 1;
            grid-row-start: 2;
            grid-row-end: 3;
            grid-column-start: 1;
            grid-column-end: 4;
        }
        &_item_2 {
            height: 100%;
            aspect-ratio: 1;
            grid-row-start: 3;
            grid-row-end: 4;
            grid-column-start: 1;
            grid-column-end: 4;
        }
        &_item_3 {
            height: 100%;
            width: 100%;
            grid-column-start: 4;
            grid-column-end: 7;
            grid-row-start: 2;
            grid-row-end: 4;
        }
        &_item_4 {
            aspect-ratio: 2/1;
            width: 100%;
            grid-column-start: 1;
            grid-column-end: 7;
            grid-row-start: 4;
            grid-row-end:5 ;
        }

        &_item_5 {
            aspect-ratio: 1.5/1;
            width: 100%;
            grid-column-start: 1;
            grid-column-end: 5;
            grid-row-start: 5;
            grid-row-end: 6;
        }

        &_item_6 {
            height: 100%;
            width: 100%;
            grid-column-start: 5;
            grid-column-end: 7;
            grid-row-start: 5;
            grid-row-end: 6;
        }
        &_item_7 {
            aspect-ratio: 1/1;
            width: 100%;
            grid-column-start: 1;
            grid-column-end: 3;
            grid-row-start: 6;
            grid-row-end: 7;
        }
        &_item_8 {
            aspect-ratio: 1/1;
            width: 100%;
            grid-column-start: 3;
            grid-column-end: 5;
            grid-row-start: 6;
            grid-row-end: 7;
        }
        &_item_9 {
            aspect-ratio: 1/1;
            width: 100%;
            grid-column-start: 5;
            grid-column-end: 7;
            grid-row-start: 6;
            grid-row-end: 7;
        }
        &_item_10 {
            aspect-ratio: 2/1;
            width: 100%;
            grid-column-start: 1;
            grid-column-end: 7;
            grid-row-start: 7;
            grid-row-end: 8;
        }
    }

    &_10 {
        &_item_0 {
            width: 100%;
            @include mainGalleryRatio1;
            grid-column-start: 1;
            grid-column-end: 7;
        }
        &_item_1 {
            height: 100%;
            aspect-ratio: 1;
            grid-row-start: 2;
            grid-row-end: 3;
            grid-column-start: 1;
            grid-column-end: 4;
        }
        &_item_2 {
            height: 100%;
            aspect-ratio: 1;
            grid-row-start: 3;
            grid-row-end: 4;
            grid-column-start: 1;
            grid-column-end: 4;
        }
        &_item_3 {
            height: 100%;
            width: 100%;
            grid-column-start: 4;
            grid-column-end: 7;
            grid-row-start: 2;
            grid-row-end: 4;
        }
        &_item_4 {
            aspect-ratio: 2/1;
            width: 100%;
            grid-column-start: 1;
            grid-column-end: 7;
            grid-row-start: 4;
            grid-row-end:5 ;
        }

        &_item_5 {
            aspect-ratio: 1.5/1;
            width: 100%;
            grid-column-start: 1;
            grid-column-end: 5;
            grid-row-start: 5;
            grid-row-end: 6;
        }

        &_item_6 {
            height: 100%;
            width: 100%;
            grid-column-start: 5;
            grid-column-end: 7;
            grid-row-start: 5;
            grid-row-end: 6;
        }
        &_item_7 {
            aspect-ratio: 1/1;
            width: 100%;
            grid-column-start: 1;
            grid-column-end: 3;
            grid-row-start: 6;
            grid-row-end: 7;
        }
        &_item_8 {
            aspect-ratio: 1/1;
            width: 100%;
            grid-column-start: 3;
            grid-column-end: 5;
            grid-row-start: 6;
            grid-row-end: 7;
        }
        &_item_9 {
            aspect-ratio: 1/1;
            width: 100%;
            grid-column-start: 5;
            grid-column-end: 7;
            grid-row-start: 6;
            grid-row-end: 7;
        }
    }

    &_9 {
        &_item_0 {
            width: 100%;
            @include mainGalleryRatio1;
            grid-column-start: 1;
            grid-column-end: 7;
        }
        &_item_1 {
            height: 100%;
            aspect-ratio: 1;
            grid-row-start: 2;
            grid-row-end: 3;
            grid-column-start: 1;
            grid-column-end: 4;
        }
        &_item_2 {
            height: 100%;
            aspect-ratio: 1;
            grid-row-start: 3;
            grid-row-end: 4;
            grid-column-start: 1;
            grid-column-end: 4;
        }
        &_item_3 {
            height: 100%;
            width: 100%;
            grid-column-start: 4;
            grid-column-end: 7;
            grid-row-start: 2;
            grid-row-end: 4;
        }
        &_item_4 {
            aspect-ratio: 2/1;
            width: 100%;
            grid-column-start: 1;
            grid-column-end: 7;
            grid-row-start: 4;
            grid-row-end:5 ;
        }

        &_item_5 {
            aspect-ratio: 1.5/1;
            width: 100%;
            grid-column-start: 1;
            grid-column-end: 5;
            grid-row-start: 5;
            grid-row-end: 6;
        }

        &_item_6 {
            height: 100%;
            width: 100%;
            grid-column-start: 5;
            grid-column-end: 7;
            grid-row-start: 5;
            grid-row-end: 6;
        }
        &_item_7 {
            height: 100%;
            width: 100%;
            grid-column-start: 1;
            grid-column-end: 3;
            grid-row-start: 6;
            grid-row-end: 7;
        }
        &_item_8 {
            aspect-ratio: 1.5/1;
            width: 100%;
            grid-column-start: 3;
            grid-column-end: 7;
            grid-row-start: 6;
            grid-row-end: 7;
        }
    }

    &_8 {
        &_item_0 {
            width: 100%;
            @include mainGalleryRatio1;
            grid-column-start: 1;
            grid-column-end: 7;
        }
        &_item_1 {
            height: 100%;
            aspect-ratio: 1;
            grid-row-start: 2;
            grid-row-end: 3;
            grid-column-start: 1;
            grid-column-end: 4;
        }
        &_item_2 {
            height: 100%;
            aspect-ratio: 1;
            grid-row-start: 3;
            grid-row-end: 4;
            grid-column-start: 1;
            grid-column-end: 4;
        }
        &_item_3 {
            height: 100%;
            width: 100%;
            grid-column-start: 4;
            grid-column-end: 7;
            grid-row-start: 2;
            grid-row-end: 4;
        }
        &_item_4 {
            aspect-ratio: 2/1;
            width: 100%;
            grid-column-start: 1;
            grid-column-end: 7;
            grid-row-start: 4;
            grid-row-end:5 ;
        }

        &_item_5 {
            aspect-ratio: 1.5/1;
            width: 100%;
            grid-column-start: 1;
            grid-column-end: 5;
            grid-row-start: 5;
            grid-row-end: 6;
        }

        &_item_6 {
            height: 100%;
            width: 100%;
            grid-column-start: 5;
            grid-column-end: 7;
            grid-row-start: 5;
            grid-row-end: 6;
        }
        &_item_7 {
            aspect-ratio: 2/1;
            width: 100%;
            grid-column-start: 1;
            grid-column-end: 7;
            grid-row-start: 6;
            grid-row-end: 7;
        }
    }

    &_7 {
        &_item_0 {
            width: 100%;
            @include mainGalleryRatio1;
            grid-column-start: 1;
            grid-column-end: 7;
        }
        &_item_1 {
            height: 100%;
            aspect-ratio: 1;
            grid-row-start: 2;
            grid-row-end: 3;
            grid-column-start: 1;
            grid-column-end: 4;
        }
        &_item_2 {
            height: 100%;
            aspect-ratio: 1;
            grid-row-start: 3;
            grid-row-end: 4;
            grid-column-start: 1;
            grid-column-end: 4;
        }
        &_item_3 {
            height: 100%;
            width: 100%;
            grid-column-start: 4;
            grid-column-end: 7;
            grid-row-start: 2;
            grid-row-end: 4;
        }
        &_item_4 {
            height: 100%;
            grid-column-start: 1;
            grid-column-end: 7;
            grid-row-start: 4;
            grid-row-end: 5;
        }

        &_item_5 {
            aspect-ratio: 1.5/1;
            height: 100%;
            grid-column-start: 1;
            grid-column-end: 5;
            grid-row-start: 5;
            grid-row-end: 6;
        }

        &_item_6 {
            height: 100%;
            width: 100%;
            grid-column-start: 5;
            grid-column-end: 7;
            grid-row-start: 5;
            grid-row-end: 6;
        }
    }

    &_6 {
        &_item_0 {
            width: 100%;
            @include mainGalleryRatio1;
            grid-column-start: 1;
            grid-column-end: 7;
        }
        &_item_1 {
            height: 100%;
            aspect-ratio: 1;
            grid-row-start: 2;
            grid-row-end: 3;
            grid-column-start: 1;
            grid-column-end: 4;
        }
        &_item_2 {
            height: 100%;
            aspect-ratio: 1;
            grid-row-start: 3;
            grid-row-end: 4;
            grid-column-start: 1;
            grid-column-end:span 3;
        }
        &_item_3 {
            height: 100%;
            width: auto;
            grid-column-start: 4;
            grid-column-end: 7;
            grid-row-start: 2;
            grid-row-end: 4;
        }

        &_item_4 {
            aspect-ratio: 1.5/1;
            height: 100%;
            grid-column-start: 1;
            grid-column-end: 5;
            grid-row-start: 4;
            grid-row-end: 5;
        }

        &_item_5 {
            height: 100%;
            width: 100%;
            grid-column-start: 5;
            grid-column-end: 7;
            grid-row-start: 4;
            grid-row-end: 5;
        }
    }

    //pour 5 images
    &_5 {
        &_item_0 {
            width: 100%;
            @include mainGalleryRatio1;
            grid-column-start: 1;
            grid-column-end: 7;
        }
        &_item_1 {
            height: 100%;
            aspect-ratio: 1;
            grid-row-start: 2;
            grid-row-end: 3;
            grid-column-start: 1;
            grid-column-end: 4;
        }
        &_item_2 {
            height: 100%;
            aspect-ratio: 1;
            grid-row-start: 3;
            grid-row-end: 4;
            grid-column-start: 1;
            grid-column-end: 4;
        }
        &_item_3 {
            height: 100%;
            width: 100%;
            grid-column-start: 4;
            grid-column-end: 7;
            grid-row-start: 2;
            grid-row-end: 4;
        }
        &_item_4 {
            aspect-ratio: 2/1;
            width: 100%;
            grid-column-start: 1;
            grid-column-end: 7;
            grid-row-start: 4;
            grid-row-end: 5;
        }
    }

    //pour 4 images
    &_4 {
        &_item_0 {
            width: 100%;
            @include mainGalleryRatio1;
            grid-column-start: 1;
            grid-column-end: 7;
        }
        &_item_1 {
            height: 100%;
            aspect-ratio: 1;
            grid-row-start: 2;
            grid-row-end: 3;
            grid-column-start: 1;
            grid-column-end: 4;
        }
        &_item_2 {
            height: 100%;
            aspect-ratio: 1;
            grid-row-start: 3;
            grid-row-end: 4;
            grid-column-start: 1;
            grid-column-end: 4;
        }
        &_item_3 {
            height: 100%;
            width: 100%;
            grid-column-start: 4;
            grid-column-end: 7;
            grid-row-start: 2;
            grid-row-end: 4;
        }
    }

    //pour 3 images
    &_3 {
        &_item_0 {
            width: 100%;
            @include mainGalleryRatio1;
            grid-column-start: 1;
            grid-column-end: 7;
        }
        &_item_1 {
            aspect-ratio: 1;
            width: calc(100% - 1.5px);
            grid-column-start: 1;
            grid-column-end: 4;
        }
        &_item_2 {
            aspect-ratio: 1;
            width: calc(100% - 1.5px);
            grid-column-start: 4;
            grid-column-end: 7;
        }
    }

    //pour 2 images
    &_2 {
       
        &_item_0 {
            width: 100%;
            @include mainGalleryRatio1;
            grid-column-start: 1;
            grid-column-end: 7;
            grid-row-start: 1;
            grid-row-end: 2;
        }

        &_item_1 {
            width: 100%;
            aspect-ratio: 2/1;
            grid-column-start: 1;
            grid-column-end: 7;
            grid-row-start: 2;
            grid-row-end: 3;
        }
    }

    //pour 1 image
    &_1 {
        &_item_0 {
            width: 100%;
            @include mainGalleryRatio1;
            grid-column-start: 1;
            grid-column-end: 7;
            grid-row-start: 1;
            grid-row-end: 2;
        }
    }

    //pour 0 image
    &_0 {
        display: none;
    }

    &:hover {
        cursor: grab;
    }

    &_image {
        display: flex;
        z-index: 2;
        width: 100%;
        height: 100%;
        object-fit: cover;   
    }

    &_buttons {
        
        position: absolute;
        bottom: 5px;
        left: 0;
        background-color: rgba($color: #000000, $alpha: 0.8);
        border-radius: 10%;

        &_supprButton, &_isMainButton, &_inRandomSelectionButton {
            color: white;
            z-index: 3;
            background: none;
            border: none;
            &:hover {
                cursor: pointer;
            }
            
        }
        &_isMainButton, &_inRandomSelectionButton {
            &--isWhite {
                color: white;
                }
            &--isOrange {
                color: $colorThird;
            }
        }
    }
}

.dndRowItem { 
    aspect-ratio: 1/1;
    position: relative;
    padding: 0;
    
    &_image {
        height: 100%;
        width: 100%;
        object-fit: cover;
        padding: 0;
    }
}