/*---------------
----- FONTS -----
---------------*/
@font-face {
    font-family: 'SpecialFont';
    src: url('../../public/assets/fonts/Mermaid1001.ttf') format('truetype');
    /* Ajouter ici les autres formats de police (.woff2, .ttf, .eot, etc.) pour la compatibilité des navigateurs */
}

@font-face {
    font-family: 'MainFont_light';
    src: url('../../public/assets/fonts/ProximaNova-Light.ttf') format('truetype');
    /* Ajouter ici les autres formats de police (.woff2, .ttf, .eot, etc.) pour la compatibilité des navigateurs */
}

@font-face {
    font-family: 'MainFont_lightItalic';
    src: url('../../public/assets/fonts/ProximaNova-LightIt.ttf') format('truetype');
    /* Ajouter ici les autres formats de police (.woff2, .ttf, .eot, etc.) pour la compatibilité des navigateurs */
}

@font-face {
    font-family: 'MainFont_bold';
    src: url('../../public/assets/fonts/ProximaNova-Bold.ttf') format('truetype');
    /* Ajouter ici les autres formats de police (.woff2, .ttf, .eot, etc.) pour la compatibilité des navigateurs */
}

/*----------------
----- MIXINS -----
----------------*/

@mixin Button {
    margin: auto;
    font-family:'SpecialFont';
    color: $colorLightGrey;
    background-color: $colorDarkGrey;
    font-size: 25px;
    padding: 10px;
    border: none;
    &:hover {
        color: $colorDarkGrey;
        background-color: $colorFirst;
        cursor: pointer;
    }
}

@mixin AddFieldButton {
    margin: auto;
    font-family:'SpecialFont';
    color: $colorLightGrey;
    font-size: 25px;
    padding: 10px;
    border: none;
    background-color: $colorDarkGrey;
    &:hover {
        color: $colorLightGrey;
        background-color: $colorDarkGrey;
        cursor: pointer;
    }
}


@mixin ButtonSelected {
    // margin: auto;
    font-family:'SpecialFont';
    font-size: 25px;
    padding: 10px;
    border: none;
    color: $colorDarkGrey;
    background-color: $colorFirst;
    // border-radius: 50rem;
    &:hover {
        
        cursor: pointer;
    }
}

@mixin AlertTextStyle {
    font-family:'MainFont_light';
    font-size: 16px;
    padding: 10px;
    border: none;
    color: $colorDarkGrey;
}

@mixin mainGalleryRatio1 {
    aspect-ratio: 2/1;
}

@mixin responsiveMarginLeft {
    margin-left: 50px;
}

@mixin responsiveMarginRight {
    width: auto;
    margin-right: 15px;
}

/*-------------------
----- VARIABLES -----
-------------------*/

$colorLightGrey: #E9E9E9;
$colorDarkGrey: #0c0c0c;
$colorFields: aqua;
$colorFirst: rgb(255, 242, 0);
$colorThird: rgb(230, 0, 255);
$colorSecond: rgb(255, 181, 192);

$mobileMaxWidth: 768px;
$padMinWidth: 769px;
$padMaxWidth: 991px;

/*-------------------
----- KEYFRAMES -----
-------------------*/

@keyframes float {
	0% {
		transform: translatey(0px);
	}
	50% {
		transform: translatey(-20px);
	}
	100% {
		transform: translatey(0px);
	}
}

@keyframes heightRetract {
    0% { 
        padding-top: 0%;
        padding-bottom: 0%;
    }
    25% {
        padding-top: 0%;
        padding-bottom: 0%;
       
    }
    100% {
        padding-top: 16px;
        padding-bottom: 16px;
       
    }
}

@keyframes heightRetractInvert {
 
    0% {
        padding-top: 16px;
        padding-bottom: 16px;
       
    }
    100% {
        padding-top: 0;
        padding-bottom: 0;
       
    }
}

@keyframes navOpen {
    0% { 
        opacity: 0;  
    }
    100% {
        opacity: 1;
    }
}

@keyframes navOpenInvert {
    0% {
        opacity: 1;
    }
    100% {
        opacity: 0;
    }
}

@keyframes showHamburger {
    0% { 
        opacity: 0;
    }
   
    100% {
        opacity: 1;
    }
}
@keyframes hideHamburger {
    0% { 
        opacity: 1;    
    }
   
    100% {
        opacity: 0;  
    }
}

@keyframes openHeader {
    0% {  
        height: 35px;
    }
    
    100% { 
        height: 98px;
    }
}

@keyframes closeVerticalHeader {
    0% {  
        width: 40%;
    }
    
    100% { 
        width: 35px;
    }
}

@keyframes openVerticalHeader {
    0% {  
        width: 35px;
    }
    
    100% { 
        width: 40%;
    }
}

@keyframes closeHeader {
    0% {  
        height: 98px;
    }
    
    100% { 
        height: 35px;
    }
}


@keyframes disappear {
    0% {  
        opacity: 1;
    }

    100% { 
        opacity: 0;
        display: none;
    }
}

@keyframes disappearValidBox {
    0% {  
        opacity: 1;
    }

    70% {  
        opacity: 1;
    }

    100% { 
        opacity: 0;
    }
}

@keyframes darkAppear {
    0% {  
        filter: brightness(0);
    }
    20% {  
        filter: brightness(0);
    }
    100% { 
        filter: brightness(1);
    }
}

@keyframes closeCollapse {

    0% {  
        transform: rotate(180deg);
    }

    100% { 
        transform: rotate(0deg);
    }
    
}

@keyframes openCollapse {
    0% {  
        transform: rotate(0deg);
    }

    100% { 
        transform: rotate(180deg);
    }
}

@keyframes openCollapseChildren {
    0% {  
        max-height: 0px;
        opacity: 0; 
    }
    50% { 
        max-height: 1000px;
        opacity: 0;
    }

    100% { 
        max-height: 1000px;
        opacity: 1;
    }
}

@keyframes closeCollapseChildren {
    0% {  
        max-height: 1000px;
        opacity: 1;
    }
    10% {  
        max-height: 1000px;
        opacity: 0;
    }

    100% { 
        max-height: 0px;
        opacity: 0;
    }
}

@keyframes appearOpacity {
    0% {  
        opacity: 0;
    }
    100% {  
        opacity: 1;
    }

}

@media only screen and (min-width: $padMinWidth) and (max-width: $padMaxWidth){

}

@media only screen and (max-width:$mobileMaxWidth){
    
}  


