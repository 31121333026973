@import '../../utils/mixins';

.oneProject {

    display: flex;
    flex-direction: column;
    animation: appearOpacity 2s ease forwards;

    &_titleContainer {
        margin: 85px 54px;
        display: flex;
        flex-direction: column;
        width: 70%;
        justify-content: space-between;
        @media only screen and (min-width: $padMinWidth) and (max-width: $padMaxWidth){
            @include responsiveMarginLeft;
            flex-direction: column;
            margin-top: 35px;
            margin-bottom: 22px;
            margin-right: 0;
            width: auto;
        }
        
        @media only screen and (max-width:$mobileMaxWidth){
            @include responsiveMarginLeft;
            flex-direction: column;
            margin-top: 35px;
            margin-bottom: 22px;
            margin-right: 0;
            width: auto;
            
        } 

        &_title {

            h4 {
                font-size: 98px;
                font-family: 'SpecialFont';
                margin: 0;
                @media only screen and (min-width: $padMinWidth) and (max-width: $padMaxWidth){
                    font-size: 85px;
                }
                
                @media only screen and (max-width:$mobileMaxWidth){
                    font-size: 40px;
                } 
            }

            p {
                font-family: 'MainFont_light';
                font-size: 30px;
                margin: 0;
                @media only screen and (min-width: $padMinWidth) and (max-width: $padMaxWidth){
                    font-size: 22px;
                }
                
                @media only screen and (max-width:$mobileMaxWidth){
                    font-size: 16px;
                } 
            }
        }

        &_projectInfos {
            display: flex;
            flex-direction: column;
            justify-content: flex-end;
            font-family: 'MainFont_light';
            font-size: 19px;
            margin: 54px 0 0 0;
            @media only screen and (min-width: $padMinWidth) and (max-width: $padMaxWidth){
                font-size: 19px;
                margin: 35px 0 0 0;
            }
            
            @media only screen and (max-width:$mobileMaxWidth){
                font-size: 16px;
                margin: 25px 0 0 0;
            } 
        }
    }
    &_firstImageContainer {
        
        img {
            width: 100%;
            @include mainGalleryRatio1;
            object-fit: cover;
            @media only screen and (min-width: $padMinWidth) and (max-width: $padMaxWidth){
                aspect-ratio: 2/1;
            }
            
            @media only screen and (max-width:$mobileMaxWidth){
                aspect-ratio: 1.5/1;
            } 
        }
    }

    &_firstInfosBlock{
        width: 72%;
        display: flex;
        flex-direction: row;
        justify-content: center;
        column-gap: 35px;
        margin: 54px auto;
        height: auto;
        @media only screen and (min-width: $padMinWidth) and (max-width: $padMaxWidth){
            margin: 25px 0;
            width: auto;
            @include responsiveMarginLeft;
        }
        
        @media only screen and (max-width:$mobileMaxWidth){
            margin: 16px 0;
            width: auto;
            flex-direction: column;
            @include responsiveMarginLeft;
        } 

        &_showAndSceno {
            width: 100%;
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
            @media only screen and (min-width: $padMinWidth) and (max-width: $padMaxWidth){
                width: 90%; 
            }
            
            @media only screen and (max-width:$mobileMaxWidth){
                @include responsiveMarginRight;
            }

            &_showBlock, &_scenoBlock {
        
                h5 {
                    font-family: 'SpecialFont';
                    font-size: 30px;
                    text-align: end;
                    margin: 22px 0;
                    @media only screen and (min-width: $padMinWidth) and (max-width: $padMaxWidth){
                        font-size: 30px;
                    }
                    
                    @media only screen and (max-width:$mobileMaxWidth){
                        margin: 10px 0;
                        font-size: 22px;
                        text-align: start;
                    } 
                }
                p {
                    text-align: justify;
                    font-family: 'MainFont_light';
                    font-size: 16px;
                    margin: 22px 0;
                    @media only screen and (min-width: $padMinWidth) and (max-width: $padMaxWidth){
                        font-size: 16px;
                    }
                    
                    @media only screen and (max-width:$mobileMaxWidth){
                        margin: 10px 0;
                        font-size: 12px;
                        text-align: justify;
                        
                    } 
                }
            }
        }

        &_distributionAndLinks {
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
            height: auto;

            &_distribution {
                width: 100%;
                display: flex;
                flex-direction: column;
                justify-content: flex-start;
                @media only screen and (min-width: $padMinWidth) and (max-width: $padMaxWidth){
                    @include responsiveMarginRight;
                }
                @media only screen and (max-width:$mobileMaxWidth){
                    @include responsiveMarginRight;
                } 

                h5 {
                    font-family: 'SpecialFont';
                    font-size: 30px;
                    text-align: start;
                    margin: 22px 0;
                    @media only screen and (min-width: $padMinWidth) and (max-width: $padMaxWidth){
                        font-size: 30px;
                    }
                    
                    @media only screen and (max-width:$mobileMaxWidth){
                        margin: 10px 0;
                        font-size: 22px;
                        text-align: start;
                    } 
                }

                &_list {
                    display: flex;
                    flex-direction: column;
                    list-style-type: none;
                    padding: 0;
                    margin: 0;
                    

                    &_item {
                        
                        p {
                            
                            font-size: 16px;
                            font-family: 'MainFont_light';
                            margin: 3px 0;
                            @media only screen and (min-width: $padMinWidth) and (max-width: $padMaxWidth){
                                
                            }
                            
                            @media only screen and (max-width:$mobileMaxWidth){
                                font-size: 12px;
                                text-align: start;
                            } 
                        }
                        span {
                            font-size: 16px;
                            font-family: 'MainFont_bold';
                            @media only screen and (min-width: $padMinWidth) and (max-width: $padMaxWidth){
                                
                            }
                            
                            @media only screen and (max-width:$mobileMaxWidth){
                                font-size: 12px;
                                text-align: start;
                            } 
                        }

                    }
                }
            }

            &_links {
                display: flex;
                flex-direction: column;
                justify-content: flex-start;
                row-gap: 10px;
                margin: 73px 0;  
                list-style-type: none;
                padding: 0;
                @media only screen and (min-width: $padMinWidth) and (max-width: $padMaxWidth){
                    
                }
                
                @media only screen and (max-width:$mobileMaxWidth){
                    margin: 30px 0;  
                }     

                a {
                    display: flex;
                    justify-content: center;
                    width: 250px;
                    text-decoration: none;
                    font-family:'SpecialFont';
                    color: $colorLightGrey;
                    background-color: $colorDarkGrey;
                    font-size: 25px;
                    padding: 10px;
                    border: none;
                    text-align: center;
                    @media only screen and (min-width: $padMinWidth) and (max-width: $padMaxWidth){
                        font-size: 19px;
                        width: 200px;
                    }
                    
                    @media only screen and (max-width:$mobileMaxWidth){
                        font-size: 16px;
                        width: 160px;
                    } 
   
                    // border-radius: 50rem;
                    &:hover {
                        color: $colorDarkGrey;
                        background-color: $colorFirst;
                        cursor: pointer;
                    }
                }
            }
        }

    }
    &_secondImageContainer {
        @media only screen and (min-width: $padMinWidth) and (max-width: $padMaxWidth){
        }
        
        @media only screen and (max-width:$mobileMaxWidth){
            padding-left: 35px;
            box-sizing: border-box;
        } 
        &_2 {
            display: flex;
            @media only screen and (min-width: $padMinWidth) and (max-width: $padMaxWidth){
                width: auto;
            }
            
            @media only screen and (max-width:$mobileMaxWidth){
                width: auto;
            } 
            &_img_0 {
                @include mainGalleryRatio1;
                width: 100%;
                object-fit: cover;
            }
        }

        &_3 {
            display: flex;
            flex-direction: row;
            column-gap: 3px;
            aspect-ratio: 2;
            width: 100%;
           
            @media only screen and (min-width: $padMinWidth) and (max-width: $padMaxWidth){  
                width: 100%;
            }
            @media only screen and (max-width:$mobileMaxWidth){
                width: 100%;
            } 

            &_img_0 {
                object-fit: cover;
                aspect-ratio: 1;
                width: calc(50% - 1.5px);
            }
            &_img_1 {
                object-fit: cover;
                width: calc(50% - 1.5px);      
            }
        }

        &_4, &_5, &_6, &_7, &_8, &_9, &_10, &_11, &_12, &_13, &_14, &_15 {
            
            display: grid;
            grid-template-columns: 1fr 1fr;
            grid-gap: 3px;
            @media only screen and (min-width: $padMinWidth) and (max-width: $padMaxWidth){
                width: auto;
            }
            
            @media only screen and (max-width:$mobileMaxWidth){
                width: auto;
            } 

            &_img_0 {

                width: 100%;
                aspect-ratio: 1;
                grid-column-start: 1;
                grid-row-start: 1;
                grid-column-end: 2;
                grid-row-end: 2;
                object-fit: cover;
            };
    
            &_img_1 {
                width: 100%;
                aspect-ratio: 1;
                grid-column-start: 1;
                grid-row-start: 2;
                grid-column-end: 2;
                grid-row-end: 3;
                object-fit: cover;
            };
    
            &_img_2 {
                
                width: 100%;
                height: 100%;
                grid-column-start: 2;
                grid-column-end: 3;
                grid-row-start: 1;
                grid-row-end: 3;
                object-fit: cover;
            };
        } 
    }

    &_productionBlocks {
    
        display: flex;
        flex-direction: column;
        padding-top: 54px;
        padding-bottom: 54px;
        background-color: white;
        
        @media only screen and (min-width: $padMinWidth) and (max-width: $padMaxWidth){
            padding-top: 0;
            padding-bottom: 0;
            width: auto;
        }
        
        @media only screen and (max-width:$mobileMaxWidth){
            padding-top: 0;
            padding-bottom: 0; 
            width: auto;
        } 

        &_container {
            margin: auto;
            width: 80%;
            display: grid;
            grid-template-columns: 1fr 1fr;
            grid-gap: 35px;
            justify-content: center;
            margin: auto;
            z-index: 0;
            @media only screen and (min-width: $padMinWidth) and (max-width: $padMaxWidth){
                grid-gap: 35px;
            }
            
            @media only screen and (max-width:$mobileMaxWidth){
                display: flex;
                flex-direction: column;
                row-gap: 22px;
            } 
    
            &_block {

                &--leftText {
                    text-align: end;
                    @media only screen and (min-width: $padMinWidth) and (max-width: $padMaxWidth){
                        
                    }
                    
                    @media only screen and (max-width:$mobileMaxWidth){
                        text-align: start;
                    } 
                }
                &--rightText {
                    text-align: start;
                }
                    h6 {
                        font-family: 'SpecialFont';
                        font-size: 22px;
                        margin: 16px 0;
                        @media only screen and (min-width: $padMinWidth) and (max-width: $padMaxWidth){
                            font-size: 22px;
                        }
                        
                        @media only screen and (max-width:$mobileMaxWidth){
                            font-size: 16px;
                        } 
                    }
                
                &_list {
                    display: flex;
                    flex-direction: column;
                    list-style-type: none;
                    padding: 0;
                    margin: 0;

                    &_item {
                        a {
                            font-size: 16px;
                            font-family: 'MainFont_light';
                            text-decoration: underline;
                            color: $colorDarkGrey;
                            @media only screen and (min-width: $padMinWidth) and (max-width: $padMaxWidth){
                                
                            }
                            
                            @media only screen and (max-width:$mobileMaxWidth){
                                
                                font-size: 12px;
                            } 
                        } 
                        span {
                                
                            font-size: 16px;
                            font-family: 'MainFont_light';
                            margin: 3px 0;
                            @media only screen and (min-width: $padMinWidth) and (max-width: $padMaxWidth){
                                
                            }
                            
                            @media only screen and (max-width:$mobileMaxWidth){
                                font-size: 12px;
                            } 
                        }
                        
                    }
                }
            }
        }
    }
    &_thirdImageContainer {

        display: grid;
        grid-template-columns: 1fr 1fr 1fr;
        grid-gap: 3px;
        width: 100%;
        margin: 3px auto;
        height: auto;
        @media only screen and (min-width: $padMinWidth) and (max-width: $padMaxWidth){
            width: auto;
        }
        
        @media only screen and (max-width:$mobileMaxWidth){
            width: auto;
            padding-left: 35px;
            box-sizing: border-box;
        } 
        
        &_5 {

            &_img_0 {

                aspect-ratio: 2/1;
                width: 100%;
                grid-column-start: 1;
                grid-row-start: 1;
                grid-column-end: 4;
                grid-row-end: 2;
                object-fit: cover;
            };

        }

        &_6 {
            &_img_0 {
                aspect-ratio: 1.5/1;
                width: 100%;
                grid-column-start: 1;
                grid-row-start: 1;
                grid-column-end: 3;
                grid-row-end: 2;
                object-fit: cover;
            };

            &_img_1 {
                height: 100%;
                width: 100%;
                grid-column-start: 3;
                grid-row-start: 1;
                grid-column-end: 4;
                grid-row-end: 2;
                object-fit: cover;
            };


        }

        &_7 {

            &_img_0 {
                aspect-ratio: 2/1;
                width: 100%;
                grid-column-start: 1;
                grid-row-start: 1;
                grid-column-end: 4;
                grid-row-end: 2;
                object-fit: cover;
            };

            &_img_1 {
                aspect-ratio: 1.33/1;
                width: 100%;
                grid-column-start: 1;
                grid-column-end: 3;
                grid-row-start: 2;
                grid-row-end: 3;
                object-fit: cover;
            };
            
            &_img_2 {
                height: 100%;
                width: 100%;
                grid-column-start: 3;
                grid-column-end: 4;
                grid-row-start: 2;
                grid-row-end: 3;
                object-fit: cover;
            };
        }

        &_8 {

            &_img_0 {
                aspect-ratio: 2/1;
                width: 100%;
                grid-column-start: 1;
                grid-row-start: 1;
                grid-column-end: 4;
                grid-row-end: 2;
                object-fit: cover;
            };

            &_img_1 {
                aspect-ratio: 1.33/1;
                width: 100%;
                grid-column-start: 1;
                grid-column-end: 3;
                grid-row-start: 2;
                grid-row-end: 3;
                object-fit: cover;
            };
            
            &_img_2 {
                height: 100%;
                width: 100%;
                grid-column-start: 3;
                grid-column-end: 4;
                grid-row-start: 2;
                grid-row-end: 3;
                object-fit: cover;
            };

            &_img_3 {

                aspect-ratio: 2/1;
                width: 100%;
                grid-column-start: 1;
                grid-row-start: 3;
                grid-column-end: 4;
                grid-row-end: 4;
                object-fit: cover;
            };
        }

        &_9 {
            &_img_0 {
                aspect-ratio: 2/1;
                width: 100%;
                grid-column-start: 1;
                grid-row-start: 1;
                grid-column-end: 4;
                grid-row-end: 2;
                object-fit: cover;
            };

            &_img_1 {
                aspect-ratio: 1.33/1;
                width: 100%;
                grid-column-start: 1;
                grid-column-end: 3;
                grid-row-start: 2;
                grid-row-end: 3;
                object-fit: cover;
            };
            
            &_img_2 {
                height: 100%;
                width: 100%;
                grid-column-start: 3;
                grid-column-end: 4;
                grid-row-start: 2;
                grid-row-end: 3;
                object-fit: cover;
            };

            &_img_3 {
                height: 100%;
                width: 100%;
                grid-column-start: 1;
                grid-row-start: 3;
                grid-column-end: 2;
                grid-row-end: 4;
                object-fit: cover;
            };

            &_img_4 {
                aspect-ratio: 1.5/1;
                width: 100%;
                grid-column-start: 2;
                grid-row-start: 3;
                grid-column-end: 4;
                grid-row-end: 4;
                object-fit: cover;
            };
        }

        &_10 {

            &_img_0 {

                aspect-ratio: 2/1;
                width: 100%;
                grid-column-start: 1;
                grid-row-start: 1;
                grid-column-end: 4;
                grid-row-end: 2;
                object-fit: cover;
            };

            &_img_1 {
                aspect-ratio: 1.33/1;
                width: 100%;
                grid-column-start: 1;
                grid-column-end: 3;
                grid-row-start: 2;
                grid-row-end: 3;
                object-fit: cover;
            };
            
            &_img_2 {
                // height: 682px;
                height: 100%;
                width: 100%;
                grid-column-start: 3;
                grid-column-end: 4;
                grid-row-start: 2;
                grid-row-end: 3;
                object-fit: cover;
            };

            &_img_3 {

                // height: 682px;
                aspect-ratio: 1/1;
                width: 100%;
                grid-column-start: 1;
                grid-row-start: 3;
                grid-column-end: 2;
                grid-row-end: 4;
                object-fit: cover;
            };

            &_img_4 {
                
                aspect-ratio: 1/1;
                width: 100%;
                grid-column-start: 2;
                grid-row-start: 3;
                grid-column-end: 3;
                grid-row-end: 4;
                object-fit: cover;
            };

            &_img_5 {
                
                aspect-ratio: 1/1;
                width: 100%;
                grid-column-start: 3;
                grid-row-start: 3;
                grid-column-end: 4;
                grid-row-end: 4;
                object-fit: cover;
            }
        }

        &_11 {

            &_img_0 {

                aspect-ratio: 2/1;
                width: 100%;
                grid-column-start: 1;
                grid-row-start: 1;
                grid-column-end: 4;
                grid-row-end: 2;
                object-fit: cover;
            };

            &_img_1 {
                // height: 682px;
                aspect-ratio: 1.33/1;
                width: 100%;
                grid-column-start: 1;
                grid-column-end: 3;
                grid-row-start: 2;
                grid-row-end: 3;
                object-fit: cover;
            };
            
            &_img_2 {
                // height: 682px;
                height: 100%;
                width: 100%;
                grid-column-start: 3;
                grid-column-end: 4;
                grid-row-start: 2;
                grid-row-end: 3;
                object-fit: cover;
            };

            &_img_3 {

                // height: 682px;
                aspect-ratio: 1/1;
                width: 100%;
                grid-column-start: 1;
                grid-row-start: 3;
                grid-column-end: 2;
                grid-row-end: 4;
                object-fit: cover;
            };

            &_img_4 {
                // height: 682px;
                aspect-ratio: 1/1;
                width: 100%;
                grid-column-start: 2;
                grid-row-start: 3;
                grid-column-end: 3;
                grid-row-end: 4;
                object-fit: cover;
            };

            &_img_5 {
                // height: 682px;
                aspect-ratio: 1/1;
                width: 100%;
                grid-column-start: 3;
                grid-row-start: 3;
                grid-column-end: 4;
                grid-row-end: 4;
                object-fit: cover;
            };

            &_img_6 {
                aspect-ratio: 2/1;
                width: 100%;
                grid-column-start: 1;
                grid-row-start: 4;
                grid-column-end: 4;
                grid-row-end: 5;
                object-fit: cover;
            };

        }

        &_12 {

            &_img_0 {

                aspect-ratio: 2/1;
                width: 100%;
                grid-column-start: 1;
                grid-row-start: 1;
                grid-column-end: 4;
                grid-row-end: 2;
                object-fit: cover;
            };

            &_img_1 {
                // height: 682px;
                aspect-ratio: 1.33/1;
                width: 100%;
                grid-column-start: 1;
                grid-column-end: 3;
                grid-row-start: 2;
                grid-row-end: 3;
                object-fit: cover;
            };
            
            &_img_2 {
                // height: 682px;
                height: 100%;
                width: 100%;
                grid-column-start: 3;
                grid-column-end: 4;
                grid-row-start: 2;
                grid-row-end: 3;
                object-fit: cover;
            };

            &_img_3 {

                // height: 682px;
                aspect-ratio: 1/1;
                width: 100%;
                grid-column-start: 1;
                grid-row-start: 3;
                grid-column-end: 2;
                grid-row-end: 4;
                object-fit: cover;
            };

            &_img_4 {
                // height: 682px;
                aspect-ratio: 1/1;
                width: 100%;
                grid-column-start: 2;
                grid-row-start: 3;
                grid-column-end: 3;
                grid-row-end: 4;
                object-fit: cover;
            };

            &_img_5 {
                // height: 682px;
                aspect-ratio: 1/1;
                width: 100%;
                grid-column-start: 3;
                grid-row-start: 3;
                grid-column-end: 4;
                grid-row-end: 4;
                object-fit: cover;
            };

            &_img_6 {
                // height: 682px;
                
                height: 100%;
                width: 100%;
                grid-column-start: 1;
                grid-row-start: 4;
                grid-column-end: 2;
                grid-row-end: 5;
                object-fit: cover;
            };

            &_img_7 {
                // height: 682px;
                width: 100%;
                aspect-ratio: 1.5/1;
                grid-column-start: 2;
                grid-row-start: 4;
                grid-column-end: 4;
                grid-row-end: 5;
                object-fit: cover;
            };
        }

        &_13  {
            &_img_0 {

                aspect-ratio: 2/1;
                width: 100%;
                grid-column-start: 1;
                grid-row-start: 1;
                grid-column-end: 4;
                grid-row-end: 2;
                object-fit: cover;
            };

            &_img_1 {
                // height: 682px;
                aspect-ratio: 1.33/1;
                width: 100%;
                grid-column-start: 1;
                grid-column-end: 3;
                grid-row-start: 2;
                grid-row-end: 3;
                object-fit: cover;
            };
            
            &_img_2 {
                // height: 682px;
                height: 100%;
                width: 100%;
                grid-column-start: 3;
                grid-column-end: 4;
                grid-row-start: 2;
                grid-row-end: 3;
                object-fit: cover;
            };

            &_img_3 {

                // height: 682px;
                aspect-ratio: 1/1;
                width: 100%;
                grid-column-start: 1;
                grid-row-start: 3;
                grid-column-end: 2;
                grid-row-end: 4;
                object-fit: cover;
            };

            &_img_4 {
                // height: 682px;
                aspect-ratio: 1/1;
                width: 100%;
                grid-column-start: 2;
                grid-row-start: 3;
                grid-column-end: 3;
                grid-row-end: 4;
                object-fit: cover;
            };

            &_img_5 {
                // height: 682px;
                aspect-ratio: 1/1;
                width: 100%;
                grid-column-start: 3;
                grid-row-start: 3;
                grid-column-end: 4;
                grid-row-end: 4;
                object-fit: cover;
            };

            &_img_6 {
                // height: 682px;
                
                height: 100%;
                width: 100%;
                grid-column-start: 1;
                grid-row-start: 4;
                grid-column-end: 2;
                grid-row-end: 5;
                object-fit: cover;
            };

            &_img_7 {
                // height: 682px;
                width: 100%;
                aspect-ratio: 1.5/1;
                grid-column-start: 2;
                grid-row-start: 4;
                grid-column-end: 4;
                grid-row-end: 5;
                object-fit: cover;
            };

            &_img_8 {
                // aspect-ratio: 2.5/1;
                width: 100%;
                aspect-ratio: 2/1;
                grid-column-start: 1;
                grid-row-start: 5;
                grid-column-end: 4;
                grid-row-end: 6;
                object-fit: cover;
            };
        }

        &_14 {
            &_img_0 {

                aspect-ratio: 2/1;
                width: 100%;
                grid-column-start: 1;
                grid-row-start: 1;
                grid-column-end: 4;
                grid-row-end: 2;
                object-fit: cover;
            };

            &_img_1 {
                // height: 682px;
                aspect-ratio: 1.33/1;
                width: 100%;
                grid-column-start: 1;
                grid-column-end: 3;
                grid-row-start: 2;
                grid-row-end: 3;
                object-fit: cover;
            };
            
            &_img_2 {
                // height: 682px;
                height: 100%;
                width: 100%;
                grid-column-start: 3;
                grid-column-end: 4;
                grid-row-start: 2;
                grid-row-end: 3;
                object-fit: cover;
            };

            &_img_3 {

                // height: 682px;
                aspect-ratio: 1/1;
                width: 100%;
                grid-column-start: 1;
                grid-row-start: 3;
                grid-column-end: 2;
                grid-row-end: 4;
                object-fit: cover;
            };

            &_img_4 {
                // height: 682px;
                aspect-ratio: 1/1;
                width: 100%;
                grid-column-start: 2;
                grid-row-start: 3;
                grid-column-end: 3;
                grid-row-end: 4;
                object-fit: cover;
            };

            &_img_5 {
                // height: 682px;
                aspect-ratio: 1/1;
                width: 100%;
                grid-column-start: 3;
                grid-row-start: 3;
                grid-column-end: 4;
                grid-row-end: 4;
                object-fit: cover;
            };

            &_img_6 {
                // height: 682px;
                
                height: 100%;
                width: 100%;
                grid-column-start: 1;
                grid-row-start: 4;
                grid-column-end: 2;
                grid-row-end: 5;
                object-fit: cover;
            };

            &_img_7 {
                // height: 682px;
                width: 100%;
                aspect-ratio: 1.5/1;
                grid-column-start: 2;
                grid-row-start: 4;
                grid-column-end: 4;
                grid-row-end: 5;
                object-fit: cover;
            };

            &_img_8 {
                width: 100%;
                aspect-ratio: 1.5/1;
                grid-column-start: 1;
                grid-row-start: 5;
                grid-column-end: 3;
                grid-row-end: 6;
                object-fit: cover;
            };
            &_img_9 {
                height: 100%;
                width: 100%;
                grid-column-start: 3;
                grid-row-start: 5;
                grid-column-end: 4;
                grid-row-end: 6;
                object-fit: cover;
            };

        }

        &_15 {
            &_img_0 {

                aspect-ratio: 2/1;
                width: 100%;
                grid-column-start: 1;
                grid-row-start: 1;
                grid-column-end: 4;
                grid-row-end: 2;
                object-fit: cover;
            };

            &_img_1 {
                // height: 682px;
                aspect-ratio: 1.33/1;
                width: 100%;
                grid-column-start: 1;
                grid-column-end: 3;
                grid-row-start: 2;
                grid-row-end: 3;
                object-fit: cover;
            };
            
            &_img_2 {
                // height: 682px;
                height: 100%;
                width: 100%;
                grid-column-start: 3;
                grid-column-end: 4;
                grid-row-start: 2;
                grid-row-end: 3;
                object-fit: cover;
            };

            &_img_3 {

                // height: 682px;
                aspect-ratio: 1/1;
                width: 100%;
                grid-column-start: 1;
                grid-row-start: 3;
                grid-column-end: 2;
                grid-row-end: 4;
                object-fit: cover;
            };

            &_img_4 {
                // height: 682px;
                aspect-ratio: 1/1;
                width: 100%;
                grid-column-start: 2;
                grid-row-start: 3;
                grid-column-end: 3;
                grid-row-end: 4;
                object-fit: cover;
            };

            &_img_5 {
                // height: 682px;
                aspect-ratio: 1/1;
                width: 100%;
                grid-column-start: 3;
                grid-row-start: 3;
                grid-column-end: 4;
                grid-row-end: 4;
                object-fit: cover;
            };

            &_img_6 {
                // height: 682px;
                
                height: 100%;
                width: 100%;
                grid-column-start: 1;
                grid-row-start: 4;
                grid-column-end: 2;
                grid-row-end: 5;
                object-fit: cover;
            };

            &_img_7 {
                // height: 682px;
                width: 100%;
                aspect-ratio: 1.5/1;
                grid-column-start: 2;
                grid-row-start: 4;
                grid-column-end: 4;
                grid-row-end: 5;
                object-fit: cover;
            };

            &_img_8 {
                // height: 682px;
                aspect-ratio: 1/1;
                width: 100%;
                grid-column-start: 1;
                grid-row-start: 5;
                grid-column-end: 2;
                grid-row-end: 6;
                object-fit: cover;
            };
            &_img_9 {
                // height: 682px;
                aspect-ratio: 1/1;
                width: 100%;
                grid-column-start: 2;
                grid-row-start: 5;
                grid-column-end: 3;
                grid-row-end: 6;
                object-fit: cover;
            };
            &_img_10 {
                // height: 682px;
                aspect-ratio: 1/1;
                width: 100%;
                grid-column-start: 3;
                grid-row-start: 5;
                grid-column-end: 4;
                grid-row-end: 6;
                object-fit: cover;
            };
        }
    }


    &_pressBlocks {
        display: flex;
        flex-direction: column;
        margin: 3px auto 0 auto;
        padding-left: 35px;
        box-sizing: border-box;
        width: 100%;
        background-color: $colorDarkGrey;
        color: $colorLightGrey;
        @media only screen and (min-width: $padMinWidth) and (max-width: $padMaxWidth){

            width: 100%;
        }
        
        @media only screen and (max-width:$mobileMaxWidth){

            width: 100%;
        } 

        h5 {
            font-family: 'SpecialFont';
            font-size: 30px;
            text-align: start;
            margin: 22px auto;
        }

        &_container {
            // display: grid;
            // grid-template-columns: 1fr 1fr 1fr;
            display: flex;
            flex-direction: row;
            justify-content: center;
            flex-wrap: wrap;
            margin: auto;
            padding: 113px 0;
            width: 80%;
            // grid-gap: 54px;
            column-gap: 98px;
            @media only screen and (min-width: $padMinWidth) and (max-width: $padMaxWidth){
                padding: 22px 0;
                flex-direction: column;
            }
            
            @media only screen and (max-width:$mobileMaxWidth){
                display: flex;
                padding: 22px 0;
                row-gap: 16px;
                flex-direction: column;
                width: 100%;
            } 

            &_item {
                list-style-type: none;
                display: flex;
                flex-direction: column;
                width: 20%;
                @media only screen and (min-width: $padMinWidth) and (max-width: $padMaxWidth){
                    width: 80%;
                    margin: auto;
                        
                }
                
                @media only screen and (max-width:$mobileMaxWidth){
                    width: 80%;
                    margin: auto;
                } 

                h6 {
                    font-family: 'SpecialFont';
                    font-size: 22px;
                    text-align:center;
                    margin: 16px 0;
                    @media only screen and (min-width: $padMinWidth) and (max-width: $padMaxWidth){
                        
                        
                    }
                    
                    @media only screen and (max-width:$mobileMaxWidth){
                        font-size: 16px;
                        margin: 9px 0;
                    } 
                }
                p {
                    font-size: 16px;
                    font-family: 'MainFont_lightItalic';
                    margin: 3px 0;
                    text-align:center;
                    @media only screen and (min-width: $padMinWidth) and (max-width: $padMaxWidth){
                                
                    }
                    
                    @media only screen and (max-width:$mobileMaxWidth){
                        font-size: 12px;
                    } 
                }

                a {
                    font-size: 16px;
                    font-family: 'MainFont_lightItalic';
                    text-decoration: none;
                    margin: 16px auto;
                    text-align: center;
                    background-color: $colorLightGrey;
                    color: $colorDarkGrey;
                    padding: 5px 9px;
                    &:hover {
                        color: $colorDarkGrey;
                        background-color: $colorFirst;
                        
                    }
                    @media only screen and (min-width: $padMinWidth) and (max-width: $padMaxWidth){
                                
                    }
                    
                    @media only screen and (max-width:$mobileMaxWidth){
                        font-size: 12px;
                    } 
                }
            }
        }
    }

    &_makingOfImageContainer {
        
        &_imagesGrid {
            display: grid;
            grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
            margin: auto;
            width: 70%;
            grid-gap: 25px;

            @media only screen and (min-width: $padMinWidth) and (max-width: $padMaxWidth){
                        
                        
            }
            
            @media only screen and (max-width:$mobileMaxWidth){
                grid-template-columns: 1fr 1fr 1fr;
            } 

            img {
                object-fit: cover;
                margin: auto;
                aspect-ratio: 1/1;
                width: 100%;

                &:hover {
                    cursor: pointer;
                }
            }
        }
        &_imageFocusContainer {
            
            z-index: 3;
            &--displayOn {
                position: fixed;
                display: flex;
                flex-direction: row;
                background-color: rgba($color: #000000, $alpha: 0.8);
                top: 0;
                left: 0;
                width: 100vw;
                height: 100vh;
                @media only screen and (min-width: $padMinWidth) and (max-width: $padMaxWidth){
                    width: 100%;
                    height: 100vh;
                }
                
                @media only screen and (max-width:$mobileMaxWidth){
                    width: auto;
                    height: 100vh;
                } 
                
            }

            &--displayOff {
                display: none;
            }
        }
    }

    &_diffBlock {
        display: flex;
        flex-direction: column;
        margin: 54px auto;
        @media only screen and (min-width: $padMinWidth) and (max-width: $padMaxWidth){
            margin: 0 auto;
        }
        
        @media only screen and (max-width:$mobileMaxWidth){
            margin: 0 auto;
        } 

        h5 {
            font-family: 'SpecialFont';
            font-size: 30px;
            text-align: start;
            margin: 22px auto;
        }

        &_list {
            list-style-type: none;
            margin: 54px auto;
                @media only screen and (min-width: $padMinWidth) and (max-width: $padMaxWidth){
                    margin: 16px auto;
                }
                
                @media only screen and (max-width:$mobileMaxWidth){
                    margin: 16px auto;
                } 
            
            &_item {
                font-size: 16px; 
                font-family: 'MainFont_light';   
                @media only screen and (min-width: $padMinWidth) and (max-width: $padMaxWidth){
                    font-size: 16px;         
                }
                
                @media only screen and (max-width:$mobileMaxWidth){
                    display: flex;
                    flex-direction: column;
                    font-size: 12px;
                    margin-bottom: 12px;
                }

                a {
                    color: $colorDarkGrey;
                    font-family: 'MainFont_bold';
                    text-decoration: none;
                    margin-right: 12px;
                    &:hover {
                        color: $colorSecond;
                    } 
                    @media only screen and (min-width: $padMinWidth) and (max-width: $padMaxWidth){
                            
                    }
                    
                    @media only screen and (max-width:$mobileMaxWidth){
                        font-size: 12px;
                    } 
                }
            }
        }
    }
}