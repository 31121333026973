@import '../../utils/mixins';

.imageFocus {

    padding-left: 35px;
    margin: auto;
    width: auto;
    @media only screen and (min-width: $padMinWidth) and (max-width: $padMaxWidth){
        width: auto;
    }
    
    @media only screen and (max-width:$mobileMaxWidth){
        margin: auto;
    } 
    
    &_container {
        display: flex;
        flex-direction: row;
        margin: auto;

    }

    img {
        // aspect-ratio: 1;
        height: 80vh;
        max-width: 100%;
        object-fit: cover;
        padding: auto;
        margin: auto;
        @media only screen and (min-width: $padMinWidth) and (max-width: $padMaxWidth){
            width: 88%;
            // aspect-ratio: 1;
        }
        
        @media only screen and (max-width:$mobileMaxWidth){
            width: 80%;
            height: 60%;
            // aspect-ratio: 1;
            margin: 0;
        } 
    }
    &_icon {
        color: $colorLightGrey;
        font-size: 25px;
        display: flex;
        margin: auto 25px;
        &:hover {
            cursor: pointer;
        }
        @media only screen and (min-width: $padMinWidth) and (max-width: $padMaxWidth){
            font-size: 25px;   
        }
        
        @media only screen and (max-width:$mobileMaxWidth){

            font-size: 16px; 
            margin: auto;  
        } 
    }
}