@import '../../utils/mixins';

.editWorkModal {

    &_confirmBox {

        z-index: 1;
        position: fixed;
        align-items: center;
        justify-content: center;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background:rgba(0, 0, 0, 0.8);

        &_container {
            display: flex;
            flex-direction: column;
            row-gap: 16px;
            padding-top: 22px;
            padding-bottom: 22px;
            width: 500px;
            height: auto;
            background-color: $colorLightGrey;
            @media only screen and (min-width: $padMinWidth) and (max-width: $padMaxWidth){
                width: 40%;
            }
            
            @media only screen and (max-width:$mobileMaxWidth){
                width: 70%;
            } 

            &_question {
                text-align: center;
                font-size: 22px;
                font-family: 'SpecialFont';
            }

            &_buttons {
                display: flex;
                flex-direction: row;
                justify-content: center;
                margin: auto;
                column-gap: 16px;

                button {
                    @include Button;
                    font-size: 19px;
                }
            }
        }

        &--displayOn {
            display: flex;
        }

        &--displayOff {
            display: none;
        }

        // &_container  {
        //     display: flex;
        //     flex-direction: column;
        //     width: 630px;
        //     height: auto;
        //     padding: 3% 0;

        //     &_question {
        //         padding-top: 20px;
        //         margin: 0 auto 5px auto;
        //         font-family: 'MainFont';
        //         color: $colorDarkGrey;
        //         display: flex;
        //         justify-content: center;
        //         width: 300px;
        //         @include MainFont;
        //         background-color: $colorSelection;
        //         padding: 12px;
        //         font-size: 16px;
                
        //     }
        //     &_buttons {
        //         display: flex;
        //         flex-direction: row-reverse;
        //         justify-content: space-between;
        //         column-gap: 20px;
        //         width: 15%;
        //         margin: auto;
                

        //         button {
        //             font-family: 'TitleFont';
        //             border: none;
        //             background: none;
        //             color: $colorSelection;
        //             width: 50%;
        //             margin: 5% auto 0 auto;
        //             padding: 2%;
        //             font-size: 18px;
        //             &:hover {
        //                 cursor: pointer;
        //                 color: $colorLightGrey;
        //             }
        //         }
        //     }
        // }
    } 
}
