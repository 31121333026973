@import '../../utils/mixins';

.allProjects {

    animation: appearOpacity 2s ease forwards;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    row-gap: 4px;
    
    @media only screen and (min-width: $padMinWidth) and (max-width: $padMaxWidth){
        
    }
    @media only screen and (max-width:$mobileMaxWidth){
        
    } 

    ul {
        list-style: none;
        padding: 0;
        @media only screen and (min-width: $padMinWidth) and (max-width: $padMaxWidth){
            
        }
        @media only screen and (max-width:$mobileMaxWidth){
            display: flex;
            flex-direction: column;
            row-gap: 9px;
        } 
    }

    &_buttonsContainer {
        display: flex;
        flex-direction: row;
        column-gap: 30px;
        margin: 114px 54px;
        
        @media only screen and (min-width: $padMinWidth) and (max-width: $padMaxWidth){
            margin: 35px 0 73px 50px;
            
        } 
        @media only screen and (max-width:$mobileMaxWidth){
            margin: 35px 0 35px 50px;
            
        } 
        
        &_button {
            list-style-type: none;
            
            &--notSelected {
                border: none;
                padding: 0;
                h3 {
                    margin: 0;
                    @include Button;
                } 
            }

            &--selected {
                border: none;
                padding: 0;
                h3 {
                    margin: 0;
                    @include ButtonSelected;
                }    
            }
        }
    }

    &_projectsList {
        display: flex;
        flex-direction: column;
        row-gap: 3px;
    }

    &_projectCard {
        padding-left: 54px;
        width: 100%;
        height: 490px;
        text-decoration: none;
        text-align: justify;
        color: $colorDarkGrey;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        column-gap: 40px;
        box-sizing: border-box;

        @media only screen and (min-width: $padMinWidth) and (max-width: $padMaxWidth){
            padding-left: 50px;
            flex-direction: column;
            height: auto;
            row-gap: 16px;
            margin-bottom: 35px;
        }
        @media only screen and (max-width:$mobileMaxWidth){
            padding-left: 50px;
            flex-direction: column;
            height: auto;
            row-gap: 9px;
            margin-bottom: 35px;
        } 

        &:hover {

            cursor: pointer;
            background-color: $colorFirst;

            .allProjects_projectCard_infos {
                animation: heightRetract 0.5s ease forwards;
            }
        }

        &_creationDate {
            padding-left: 54px;
            padding-top: 54px;
            width: 1%;
            margin: auto;
        }

        &_infos {
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
            box-sizing: border-box;
            width: 40%;
            height: 100%;
            animation: heightRetractInvert 0.5s ease forwards;
            
            @media only screen and (min-width: $padMinWidth) and (max-width: $padMaxWidth){
                width: 95%;
            }
            
            @media only screen and (max-width:$mobileMaxWidth){
                width: 95%;
            } 
            

            &_title {
                font-family: 'SpecialFont';
                font-size: 50px;
                margin: 30px 0 0 0;
                text-align: left;

                @media only screen and (min-width: $padMinWidth) and (max-width: $padMaxWidth){
                    font-size: 35px;
                    margin: 0;
                } 
                @media only screen and (max-width:$mobileMaxWidth){
                    font-size: 35px;
                    margin: 0;
                } 
            }

            &_subtitle {
                font-family: 'MainFont_light';
                font-size: 22px;
            }

            &_projectInfos {    
                font-family: 'MainFont_bold';
                font-size: 19px;
                margin: auto 0;

                @media only screen and (min-width: $padMinWidth) and (max-width: $padMaxWidth){
                    font-size: 19px;
                }
                @media only screen and (max-width:$mobileMaxWidth){
                    font-size: 14px;
                } 
            }

            &_creationDate {
                font-family: 'MainFont_light';
                font-size: 19px;
                color: $colorDarkGrey;
                margin: auto 0;
            }

            &_summary {   
                font-family: 'MainFont_light';
                font-size: 16px;
                height: 161px;
                overflow: auto;

                @media only screen and (min-width: $padMinWidth) and (max-width: $padMaxWidth){
                    height: auto;
                }
                @media only screen and (max-width:$mobileMaxWidth){
                    display: none;
                    height: auto;
                } 
            }
        }

        &_imageContainer {
            width: 60%;
            height: 100%;
            box-sizing: border-box;
            margin: 0;

            @media only screen and (min-width: $padMinWidth) and (max-width: $padMaxWidth){
               @include responsiveMarginRight;
            }
            
            @media only screen and (max-width:$mobileMaxWidth){
                @include responsiveMarginRight;
            } 

            img {
                width: 100%;
                height: 490px;
                object-fit: cover;

                @media only screen and (min-width: $padMinWidth) and (max-width: $padMaxWidth){
                    height: auto;
                    aspect-ratio: 2;
                }
                @media only screen and (max-width: $mobileMaxWidth){
                    height: auto;
                    aspect-ratio: 2;
                }                 
            }            
        }
    }
}