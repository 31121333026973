@import '../../utils/mixins';

.edit {
    margin-left: 54px;
    
    animation: appearOpacity 2s ease forwards;
    @media only screen and (min-width: $padMinWidth) and (max-width: $padMaxWidth){
        @include responsiveMarginLeft;
    }
    
    @media only screen and (max-width:$mobileMaxWidth){
        @include responsiveMarginLeft;
    } 
    &_warning {
        display: flex;
        flex-direction: row;
        justify-content: center;
        font-size: 35px;
        color: $colorDarkGrey;
        width: 100%;
        margin-top: 25%;
        font-family: 'SpecialFont';
        
        &--displayOff {
            display: none;
        }
    }

    &_wrapper {
        
        width: 95%;
        display: flex;
        flex-direction: column;

        &--displayOff {
            display: none;
        }
    }


    &_list {

        margin: 54px 0;
        padding: 0;
        
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;
        @media only screen and (min-width: $padMinWidth) and (max-width: $padMaxWidth){
            grid-template-columns: 1fr 1fr;
        }
        
        @media only screen and (max-width:$mobileMaxWidth){
            display: flex;
            flex-direction: column;
        } 
        
        &_item {
            display: flex;
            flex-direction: column;
            row-gap: 12px;
            margin-bottom: 22px;

            &_image {
                width: 80%;
                aspect-ratio: 1.5/1;
                object-fit: cover;
                margin: auto;
                @media only screen and (min-width: $padMinWidth) and (max-width: $padMaxWidth){
                    
                }
                
                @media only screen and (max-width:$mobileMaxWidth){
                    width: 60%;
                } 
            }

            &_title {
                width: 80%;
                font-family: 'SpecialFont';
                font-size: 19px;
                overflow: hidden;
                color: $colorDarkGrey;
                margin: auto;
                text-align: center;
            }

            &_buttonsBox {
                display: flex;
                flex-direction: row;
                column-gap: 12px;
                margin-bottom: 16px;
                margin: auto;
                @media only screen and (min-width: $padMinWidth) and (max-width: $padMaxWidth){
                    flex-direction: column;
                    row-gap: 12px;
                }
                
                @media only screen and (max-width:$mobileMaxWidth){
                    flex-direction: column;
                    row-gap: 12px;
                } 

                &_modif {
                    @media only screen and (min-width: $padMinWidth) and (max-width: $padMaxWidth){
                        display: none
                    }
                    
                    @media only screen and (max-width:$mobileMaxWidth){
                        display: none
                    } 
                }
        
                button {
                    font-family: 'MainFont_light';
                    font-size: 16px;
                    color: $colorDarkGrey;
                    background-color: $colorFirst;
                    border: none;
                    @media only screen and (min-width: $padMinWidth) and (max-width: $padMaxWidth){
                        width: auto;
                    }
                    
                    @media only screen and (max-width:$mobileMaxWidth){
                        width: auto;
                    } 
                    &:hover {
                        cursor: pointer;
                        background-color: $colorDarkGrey;
                        color: $colorLightGrey;
                    }
                }
            }
        }
    }

    &_addButton {
        margin: auto;
        @include Button;
    }
  

    &_form {

        &--displayOn{
            z-index: 1;
            position: fixed;
            align-items: center;
            justify-content: center;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background:rgba(0, 0, 0, 0.8);
        }
        &--displayOff{
            display: none;
        } 
    }

    &_randomImages {
        
        display: flex;
        flex-direction: column;
        margin-top: 98px;

        &_title {
            width: 95%;
            margin: auto;
            text-align: center;
            margin-bottom: 46px;
        }

        &_list {
            width: 80%;
            display: flex;
            flex-direction: row;
            justify-content: center;
            gap: 16px;
            flex-wrap: wrap;
            margin: 0 auto 54px auto;

            &_item {
                img {
                    width: 200px;
                    aspect-ratio: 1.5/1;
                }
            }
        }  
    }
}