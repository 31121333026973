.dndGrid {
    width: 400px;
    display: grid;
    justify-content: center;
    align-items: center;
    margin: auto;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
    gap: 2px;

    &_item {
        width: auto;
        
        list-style-type: none;

        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }
}

.dndRow {
    aspect-ratio: 3/1;
    width: 90%;
    height: 100%;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
    box-sizing: content-box;
    align-items: center;
    margin: auto;
    grid-gap: 19px;
}